import { graphql } from "gatsby"
import React from "react"

import Button from "../components/Button/Button"
import Hero, {
  colors,
  variations as heroVariations,
  titleColors,
} from "../components/Hero/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectCampaignsPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import s from "./campaigns.module.scss"
import LocalizedLink from "../components/common/LocalizedLink"
import Img from "../components/common/Img"

export default function CampaignsPage(props) {
  const { data, pageContext } = props
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const {
    seo,
    heroText,
    menuButtonText,
    menuButtonLinkTo,
    locationButtonText,
    locationButtonLinkTo,
    campaign,
  } = selectCampaignsPageContent(data)
  const translations = selectTranslations(data)

  return (
    <Layout
      className={s.campaignsPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <Hero
        campaigns
        color={colors.grenadier}
        locale={locale}
        title={heroText}
        titleColor={titleColors.bright}
        variation={heroVariations.compact}
      />
      {campaign.map((item, index) => {
        const loadingStrategy = index === 0 ? "eager" : "lazy"

        if (index % 2 === 0) {
          return (
            <section className={s.content} key={index}>
              <div className={s.row}>
                <section className={s.campaigns}>
                  <h2>{item.title}</h2>
                  <p
                    className={s.description}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </section>
                <section className={s.imageSection}>
                  <Img
                    alt={item.imageAlt}
                    className={s.image}
                    loading={loadingStrategy}
                    objectFit="contain"
                    src={item.image}
                  />
                </section>
              </div>
            </section>
          )
        } else {
          return (
            <section className={s.content} key={index}>
              <div className={s.row}>
                <section className={s.imageSection}>
                  <Img
                    alt={item.imageAlt}
                    className={s.image}
                    loading={loadingStrategy}
                    objectFit="contain"
                    src={item.image}
                  />
                </section>
                <section className={s.campaigns}>
                  <h2>{item.title}</h2>
                  <p
                    className={s.description}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </section>
              </div>
            </section>
          )
        }
      })}
      <section className={s.buttonsContainer}>
        <LocalizedLink className={s.link} to={menuButtonLinkTo}>
          <Button className={s.button} text={menuButtonText} />
        </LocalizedLink>
        <LocalizedLink className={s.link} to={locationButtonLinkTo}>
          <Button className={s.button} text={locationButtonText} />
        </LocalizedLink>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query CampaignsPage($locale: String) {
    ...LayoutFragment
    ...CampaignsPageFragment
    ...TranslationsFragment
  }
`
